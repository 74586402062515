import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'counter']
  static values = { countdown: Boolean, enforceMaxLength: Boolean }

  connect() {
    console.log('Connected to character counter controller');
    console.log("enforceMaxLengthValue: ", this.enforceMaxLengthValue);
    console.log("countdownValue: ", this.countdownValue);
    if (this.isTrixEditor()) {
      this.waitForTrixEditor().then(() => this.addEventListeners());
    } else {
      this.addEventListeners();
    }
  }

  disconnect() {
    this.inputTarget.removeEventListener(this.inputEvent, this.update);
  }

  addEventListeners() {
    this.update();
    this.inputTarget.addEventListener(this.inputEvent, this.update);
  }

  update = () => {
    const currentLength = this.getCurrentInputLength();
    const maxLength = this.maxLength;

    if (this.enforceMaxLengthValue && currentLength > maxLength) {
      this.trimContent();
    }

    this.counterTarget.innerHTML = this.calculateCount();
  }

  calculateCount() {
    const currentLength = this.getCurrentInputLength();
    const maxLength = this.maxLength;
    return `${currentLength} / ${maxLength}`;
  }

  getCurrentInputLength() {
    if (this.isTrixEditor()) {
      return this.inputTarget.editor.getDocument().toString().trim().length;
    }
    return this.inputTarget.value.trim().length;
  }

  trimContent() {
    if (this.isTrixEditor()) {
      const content = this.inputTarget.editor.getDocument().toString();
      const trimmedContent = content.substring(0, this.maxLength);
      this.inputTarget.editor.setSelectedRange([0, content.length]);
      this.inputTarget.editor.insertString(trimmedContent);
    } else {
      this.inputTarget.value = this.inputTarget.value.substring(0, this.maxLength);
    }
  }

  get maxLength() {
    return parseInt(this.inputTarget.getAttribute('data-max-length')) || this.inputTarget.maxLength || 0;
  }

  get inputEvent() {
    return this.isTrixEditor() ? 'trix-change' : 'input';
  }

  isTrixEditor() {
    return this.inputTarget.tagName === 'TRIX-EDITOR';
  }

  waitForTrixEditor() {
    return new Promise((resolve) => {
      if (this.inputTarget.editor) {
        resolve();
      } else {
        this.inputTarget.addEventListener('trix-initialize', () => resolve(), { once: true });
      }
    });
  }
}
